
import Vue from "vue";
import CardAutocomplete from "../../../../components/Content/CardAutocomplete.vue";
import { getError } from "../../../../utils/resolveObjectArray";
import { isRequired } from "../../../../services/rule-services";
// @ts-ignore
import { initModifierTerm } from "@/utils/initData";
import { isNull, isUndefined } from "lodash";
const CONTENT_CATEGORY = "Content Category";
const CONTENT_RATING = "Content Rating";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
//@ts-ignore
import ButtonTooltip from "@/components/Content/Commons/ButtonTooltip.vue";
//@ts-ignore
import { prepareTooltipData } from "@/views/Admin/Persons/V4/Dependencies/FormFilters/utils";
import { mapActions } from "vuex";
const LANGUAGE = "Language";

// Default Matching Type
const MATCHING_TYPE_DEFAULT = "Equals";

export default Vue.extend({
	name: "ContentModule",
	props: {
		module_id: {
			type: [Number, String],
			default: undefined,
		},
		module_name: {
			type: String,
			default: "Content",
		},
		matching_types: {
			type: Array,
			default() {
				return []
			}
		},
		modifiers_options: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},
	components: {
		CardAutocomplete,
		ButtonTooltip
	},
	data: () => ({
		valid: false,
		entity: initModifierTerm(),
		valueContentCategory: null,
		valueContentRating: null,
		valueLanguage: null,
	}),
	created() {},
	async mounted() {
		this.setLoadingData(TypeLoading.loading)
		await this.dispatchCategories();
		await this.dispatchRatings();
		await this.dispatchLanguages();
		await this.setLoadingData();
	},
	computed: {
		/**
		 * GET
		 */
		getModuleSelected(){return this.module_id;},

		getCategories(): any[]{return this.$store.state.modifier_options.content_categories;},

		getRatings(): any[]{return this.$store.state.modifier_options.content_ratings;},

		getLanguages(): any[]{return this.$store.state.modifier_options.languages;},

		getErrors() {return this.$store.state.proccess.errors;},

		getRules() {
			return {
				isRequired,
			};
		},

		getLabelContentCategory(){return CONTENT_CATEGORY;},

		getLabelContentRating(){return CONTENT_RATING;},

		getLabelLanguage(){return LANGUAGE;},

		getName(){return this.module_name;},

		getMatchingTypes(){return this.matching_types;},
		
		getMatchingTypeId(): Number{
			return this.getMatchingTypes.find(e => e.value == MATCHING_TYPE_DEFAULT)?.id;
		},
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		getError(index: string) {
			return getError(this.getErrors, index);
		},

		handleChangeSelection(option: string){
			if( isNull(option) ){return;}
			let entity: any = initModifierTerm();
			switch (option) {
				case CONTENT_CATEGORY:
					entity.key = "content_category";
					entity.value = this.getContentCategoryName(this.valueContentCategory);
					entity.value_name = this.getContentCategoryValueName(this.valueContentCategory);
					entity.override_multiplier = this.isOverride("content_category");
					this.valueContentCategory = null;
					break;
				case CONTENT_RATING:
					entity.key = "content_rating";
					entity.value = this.getContentRatingName(this.valueContentRating);
					entity.value_name = this.getContentRatingValueName(this.valueContentRating);
					entity.override_multiplier = this.isOverride("content_rating");
					this.valueContentRating = null;
					break;
				case LANGUAGE:
					entity.key = "language";
					entity.value = this.getLanguageName(this.valueLanguage);
					entity.value_name = this.getLanguageValueName(this.valueLanguage);
					entity.override_multiplier = this.isOverride("language");
					this.valueLanguage = null;
					break;
			}
			if( isNull(entity.value) || isUndefined(entity.value) ){return;}
			entity.value = entity.value.toString();
			entity.module_id = this.getModuleSelected;
			entity.module_name = this.getName;
			entity.matching_type_id = this.getMatchingTypeId;
			entity.matching_type_name = MATCHING_TYPE_DEFAULT;
			this.$emit("add-term", entity);
		},

		isOverride(key: any){
			const modifierOption = this.modifiers_options[key];
			const hasOverrideMultiplier: boolean = !isUndefined(
				modifierOption?.override_multiplier
			);
			return hasOverrideMultiplier ? modifierOption.override_multiplier == "true"
				? 1
				: 0
			: undefined
		},

		async dispatchCategories() {
			return this.$store.dispatch("modifier_options/getContentCategories", {
				root: true,
			});
		},

		async dispatchRatings() {
			return this.$store.dispatch("modifier_options/getContentRatings", {
				root: true,
			});
		},

		async dispatchLanguages() {
			return this.$store.dispatch("modifier_options/getLanguages", {
				root: true,
			});
		},

		getContentCategoryName(id: Number): String{
			return this.getCategories.find(e => e.id == id)?.extra;
		},

		getContentRatingName(id: Number): String{
			return this.getRatings.find(e => e.id == id)?.extra;
		},

		getLanguageName(id: Number): String{
			return this.getLanguages.find(e => e.id == id)?.extra;
		},

		getContentCategoryValueName(id: Number): String{
			return this.getCategories.find(e => e.id == id)?.description;
		},

		getContentRatingValueName(id: Number): String{
			return this.getRatings.find(e => e.id == id)?.description;
		},

		getLanguageValueName(id: Number): String{
			return this.getLanguages.find(e => e.id == id)?.description;
		},

		/**
		 * Tootip data
		 * @param params
		 * @returns
		 */
		tooltipData(params: any) {
			return prepareTooltipData(params);
		},
	},
	watch: {}
});
