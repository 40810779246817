import i18n from "@/plugins/i18n";
import { isUndefined, isEmpty } from "lodash";

export function prepareTableHeaders(){
    return [
        {
            text: "Module",
            align: "start",
            sortable: false,
            filterable: false,
            value: "module_name",
        },
        {
            text: "Key",
            align: "start",
            sortable: false,
            filterable: false,
            value: "key",
        },
        {
            text: "Type",
            align: "start",
            sortable: false,
            filterable: false,
            value: "matching_type_name",
        },
        {
            text: "Value",
            align: "center",
            sortable: false,
            filterable: false,
            value: "value_name",
        },
        {
            text: "Multiplier",
            align: "center",
            sortable: false,
            filterable: false,
            value: "modifier",
            width: "125px",
        },
        {
            text: "Dinamic",
            align: "center",
            sortable: false,
            filterable: false,
            value: "override_multiplier",
            width: "125px",
        },
        {
            text: "",
            align: "center",
            sortable: false,
            filterable: false,
            value: "actions",
        },
    ];
}

export function prepareTableHeadersDelivery(term: any[]){
    let arr = new Array();
    const firtObj = {
        text: "Priority",
        align: "center",
        sortable: false,
        filterable: false,
        value: "rank",
        width: "50px",
    };
    arr.push(firtObj);
    if(!isEmpty(term)){
        term.forEach(element => {
            if(arr.findIndex((e) => e.value == element.key) != -1) return;
            arr.push(prepareItemHeader(element.key));
        });
    }
    const WeightCol = {
        text: "Weight",
        align: "center",
        sortable: false,
        filterable: false,
        width: "125px",
        value: "weight",
    };
    const porCol = {
        text: "%",
        align: "center",
        sortable: false,
        filterable: false,
        width: "125px",
        value: "porcentaje",
    };
    const deleteCol = {
        text: "",
        align: "center",
        sortable: false,
        filterable: false,
        width: "20px",
        value: "delete",
    };
    arr.push(WeightCol);
    arr.push(porCol);
    arr.push(deleteCol);
    return arr;
}
export function deleteTerm(term:any[]){
	let arr = new Array();
	term.forEach(element => {
        if(arr.findIndex((e) => e.text == element.module_name) != -1) return;
        arr.splice(element.key, element.module_name);
    });
	return arr;

}
export function prepareItemHeader(key: any){
    return {
        align:"start",
        filterable:false,
        sortable:false,
        text: i18n.t('modifier.labels.' + key),
        value: key
    }
}

export function prepareTooltipData(params: any) {
	return {
		icon: "mdi-information-outline",
		text: params,
		to: "",
		top: true,
		max_width: "500",
	};
}

export function prepareDataDelivery(data) {
    const {
        advertiser_id,
        name,
        fallback_weight,
        terms,
        alternative_id,
        id
    } = data;

    const newTerms: {}[] = []
    terms.forEach(term => {
        const newTerm = {
            weight: term.weight,
            rank: term.rank,
            targeting: term.targeting.map(target => {
                const newTarget = {
                    key: target.key,
                    value: target.value
                }

                return newTarget
            })
        }

        if(term.targeting.filter(target => target.value).length > 0) {
            newTerms.push(newTerm);
        }
    })
    
    return {
        advertiser_id,
        name,
        fallback_weight,
        terms: newTerms,
        alternative_id,
        id
    };
}