

import Vue from "vue";
import CardAutocomplete from "../../../../components/Content/CardAutocomplete.vue";
import CardTextField from "../../../../components/Content/CardTextField.vue";
import { isRequired } from "../../../../services/rule-services";
//@ts-ignore
import { initModifierTerm } from "@/utils/initData";
import { isNull, isUndefined, isEmpty } from "lodash";
import { getError } from "../../../../utils/resolveObjectArray";
//@ts-ignore
import ButtonTooltip from "@/components/Content/Commons/ButtonTooltip.vue";
//@ts-ignore
import { prepareTooltipData } from "@/views/Admin/Persons/V4/Dependencies/FormFilters/utils";
import { mapActions } from "vuex";

const MATCHING_TYPE_EQUALS = "Equals";

const AUCTION_TYPE = "Auction Type";
const INVENTORY_SOURCE = "Inventory Source";

export default Vue.extend({

    name: "ExchangeModule",

    props: {
        module_id: {
			type: [Number, String],
			default: undefined,
		},
		module_name: {
			type: String,
			default: "Exchange",
		},
		matching_types: {
			type: Array,
			default() {
				return []
			}
		},
		modifiers_options: {
			type: Object,
			default: function () {
				return {};
			},
		},
    },

    components: {
        CardAutocomplete,
        CardTextField,
		ButtonTooltip
    },

    data: () => ({
		valid: false,
		entity: initModifierTerm(),
		valueAuctionType: null,
		valueInventorySource: null,
		dataVariable:{
			dataAuctionTypes:[],
			dataInventorySources:[],
		},
	}),

    computed: {

		getModuleSelected(){return this.module_id;},

		getName(){return this.module_name;},

		getErrors() {return this.$store.state.proccess.errors;},

		getRules() {
			return {
				isRequired,
			};
		},

		getLabelAuctionType(){return AUCTION_TYPE;},

		getLabelInventorySource(){return INVENTORY_SOURCE;},
    },

    methods: {

		...mapActions("proccess", ["setLoadingField"]),
		...mapActions("modifier_options", ["getAuctionTypes", "getInventorySources"]),

		getError(index: string) {
			return getError(this.getErrors, index);
		},
		
		handleChangeSelection(option: string){
			if( isNull(option) ){return;}
			let entity: any = initModifierTerm();
			switch (option) {
				case AUCTION_TYPE:
					entity.key = "auction_type";
					let a = this.getAttributes(option, this.valueAuctionType);
					let matchingTypeAution = this.getMatchingTypeByType(MATCHING_TYPE_EQUALS);
					entity.value = a.value;
					entity.value_name = a.value_name;
					entity.matching_type_id = matchingTypeAution?.id;
					entity.matching_type_name = matchingTypeAution?.value;
					entity.override_multiplier = a.override_multiplier;
					this.valueAuctionType = null;
					break;
				case INVENTORY_SOURCE:
					entity.key = "inventory_source";
					let i = this.getAttributes(option, this.valueInventorySource);
					let matchingTypeInventory = this.getMatchingTypeByType(MATCHING_TYPE_EQUALS);
					entity.value = i.value;
					entity.value_name = i.value_name;
					entity.matching_type_id = matchingTypeInventory?.id;
					entity.matching_type_name = matchingTypeInventory?.value;
					entity.override_multiplier = i.override_multiplier;
					this.valueInventorySource = null;
					break;
			}
			if( isNull(entity.value) || isUndefined(entity.value) ){return;}
			entity.value = entity.value.toString();
			entity.module_id = this.getModuleSelected;
			entity.module_name = this.getName;
			this.$emit("add-term", entity);
		},

		async fetchAuctionTypes(){
			if(isEmpty(this.dataVariable.dataAuctionTypes)){
				await this.setLoadingField(true);
				await this.getAuctionTypes().then((response) => {
					this.dataVariable.dataAuctionTypes = response;
					this.setLoadingField(false);
				}).catch((error) => {
					this.setLoadingField(false);
				});
			}
		},

		async fetchInventorySources(){
			if(isEmpty(this.dataVariable.dataInventorySources)){
				await this.setLoadingField(true);
				await this.getInventorySources().then((response) => {
					this.dataVariable.dataInventorySources = response;
					this.setLoadingField(false);
				}).catch((error) => {
					this.setLoadingField(false);
				});
			}
		},

		isOverride(key: any){
			const modifierOption = this.modifiers_options[key];
			const hasOverrideMultiplier: boolean = !isUndefined(
				modifierOption?.override_multiplier
			);
			return hasOverrideMultiplier ? modifierOption.override_multiplier == "true"
				? 1
				: 0
			: undefined
		},

		getAttributes(option: any, id?: Number){
			let dataAttribute = {value: undefined, value_name: undefined, override_multiplier: undefined};
			switch (option) {
				case AUCTION_TYPE:
					let auction = this.dataVariable.dataAuctionTypes.find(e => e.id == id);
					dataAttribute.value = auction?.extra;
					dataAttribute.value_name = auction?.description;
					dataAttribute.override_multiplier = this.isOverride("auction_type");
					break;
				case INVENTORY_SOURCE:
					let inventory = this.dataVariable.dataInventorySources.find(e => e.id == id);
					dataAttribute.value = inventory?.key;
					dataAttribute.value_name = inventory?.name;
					dataAttribute.override_multiplier = this.isOverride("inventory_source");
					break;
			}
			return dataAttribute;
		},

		getMatchingTypeByType(type: any){
			return this.matching_types.find((m) => m.value == type);
		},

		/**
		 * Tootip data
		 * @param params
		 * @returns
		 */
		tooltipData(params: any) {
			return prepareTooltipData(params);
		},

    },

});

