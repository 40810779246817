import Vue from "vue";
// @ts-ignore
import HeaderTableDelivery from "@/views/Admin/Modifiers/List/HeaderTableDelivery.vue";
// @ts-ignore
import { debounce, isEmpty, isNull, isObject } from "lodash";

import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
// @ts-ignore
import CardTextField from "@/components/Content/CardTextField.vue";

// @ts-ignore
import ButtonTooltip from "@/components/Content/Commons/ButtonTooltip.vue";
import { mapActions } from "vuex";
import { TypeLoading } from "@/interfaces/loading";
export default Vue.extend({
  name: "TableDelivery",
  props: {
    headers: {
      type: Array,
      default: [],
    },
    items: {
      type: Array,
      default: [],
    },
    fallback_weight: String,
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    HeaderTableDelivery,
    CardTextField,
    ButtonTooltip,
    CardAutocomplete
  },
  data: () => ({
    resources: {
      ad_size: [],
      app_bundle_list: [],
      app_name: []
    }
  }),
  created() {
    this.getAppNameData = debounce(async function (term) {
      if (isNull(term) || (term && term.length < 3)) return;
      await this.fetchingByAttribute(
        "app_name",
        term,
        {
          key: "app_name",
          value: "app_name",
        }
      );
    }, 750)
  },
  mounted() {
    this.$nextTick(async () => {
      this.fetchAdSize()
      this.fetchAppBundleList()
    });
  },
  computed: {
    getSlot() {
      return this.items.map((v) => v.key);
    },
    getItems() {
      return this.items.map(item => {
        item.targeting.forEach(target => {
          item[target.key] = target
        })
        this.calculo(item)
        return item
      });
    },
    computedFallback: {
      get() {
        return isNaN(this.fallback_weight) ? "" : this.fallback_weight
      },
      set(value) {
        this.$emit('fallback_change', value)
      }
    },
    getFallback() {
      return {
        percent: ((parseFloat(this.computedFallback) * 100) / this.total).toFixed(2)
      }
    },
    total() {
      return this.items.reduce(
        (accum, item) => accum + parseFloat(item.weight),
        parseFloat(this.computedFallback) || 0,
      ).toFixed(2);
    },
  },
  methods: {
    ...mapActions("modifier_options", ["getSizes"]),
    ...mapActions("proccess", ["setLoadingField"]),
    ...mapActions("targeting", ["getTargetingKey"]),
    ...mapActions("targeting", ["getAppNameByAtribute"]),
    ...mapActions("loading", ["setLoadingData"]),

    resourcesAppName(item) {
      if (isNull(item.app_name.value)) {
        return this.resources.app_name
      } else {
        return [
          ...this.resources.app_name,
          {
            id: item.app_name.value,
            value: item.app_name.value
          }
        ]
      }
    },

    async handleRemoveColumn(column) {
      this.$emit("delete-column", column.value);
    },

    async handleRemoveItem(item) {
      this.$emit("delete-item", item);
    },

    calculo(item: any) {
      let result = (item.weight * 100) / this.total;

      if (isNaN(result)) {
        item["porcentaje"] = 100;
        return result;
      }

      item["porcentaje"] = result.toFixed(2);
    },

    // FETCH AD SIZE
    async fetchAdSize() {
      if (isEmpty(this.resources.ad_size)) {
        await this.setLoadingField(true);
        await this.getSizes().then((response) => {
          this.resources.ad_size = response;
          this.setLoadingField(false);
        }).catch((error) => {
          this.setLoadingField(false);
        });
      }
    },

    // FETCH APP BUNDLE LIST
    async fetchAppBundleList() {
      if (isEmpty(this.resources.app_bundle_list)) {
        await this.setLoadingField(true);
        await this.getTargetingKey({ key: "app_bundle_list" }).then((response) => {
          this.resources.app_bundle_list = response;
          this.setLoadingField(false);
        }).catch((error) => {
          this.setLoadingField(false);
        });
      }
    },

    async fetchingByAttribute(
      key: any,
      term: any,
      object: { key: any; value: any }
    ) {
      await this.setLoadingData(TypeLoading.loading);
      const result: Array<any> = await this.getAppNameByAtribute({
        key,
        term,
        by_attribute: object.key,
        object,
      });
      await this.setLoadingData();
      this.resources[key] = result;
    },

    handleChange(item, value) {
      const _ID: any | null = isObject(value) ? value["id"] : value;
      if (!isNull(item)) {
        item.value = _ID;
        item.appNameTerm = ""
      }
    },

    async syncDataAppName(item, term: string | null = null) {
      item.appNameTerm = term
      await this.getAppNameData(term, item);
    },
  },
});
